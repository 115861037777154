import React, { useState } from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import WidgetStats from "../components/widgets/stats"
import AboutYellowContent from "../components/about-content"
// import WidgetCentralImage from "../components/widgets/central-image"
import PlaySVG from "../components/SVGs/play.inline.svg"
import RichTextRenderer from "../elements/RichTextRenderer"
import Widget from "../components/widgets"
import { GatsbyImage } from "gatsby-plugin-image"
// import CentralImage from "../components/central-image"
import ModalVideo from "../elements/ModalVideo"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import AboutMission from "../components/about-mission"
import WidgetGroup from "../components/widgets/widgets-group"

export interface IContentTemplate {
  title: string
  seoTitle: string
  seoDescription: string
  text: string
  heroSubheading: string
  heroHeading: object
  heroContent: object
  heroButton: {
    url: string
    label: string
  }
  heroImage: object

  yellowContentHeader: string
  yellowContent: object

  sectionIntroHeader: string
  sectionIntroContent: object

  centralImage: any

  missionTitle: string
  missionContent: object
  missionImage: {
    fluid: any
  }

  region: Array<any>
}

export interface IContentTemplateProps {
  contentfulCareersAboutPage: IContentTemplate
}

interface IContentTemplatePageContext {
  id: string
  slug: string
  locale: string
}

const ContentTemplate: React.FC<PageProps<IContentTemplateProps, IContentTemplatePageContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersAboutPage.seoTitle || data.contentfulCareersAboutPage.title,
    description: data.contentfulCareersAboutPage.seoDescription,
    image: "",
  }

  const openModal = (e: any) => {
    e.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout seoData={seoData} dispatch={() => false}>
        <section className="hero hero-video">
          <div className="container">
            <div className="row justify-content-between flex-row-reverse">
              <div className="col-lg-6 hero-video-arrow">
                <a className="js-video" href="#" onClick={openModal}>
                  <GatsbyImage
                    image={data.contentfulCareersAboutPage.heroImage.fluid}
                    className="img-fluid hero-image-angle"
                    alt={data.contentfulCareersAboutPage.heroImage.title || ""}
                  />
                  <span className="play-icon">
                    <PlaySVG className="icon-sprite" />
                    <div className="sr-only">{data.contentfulCareersAboutPage.heroButton.label}</div>
                  </span>
                </a>
                <ModalVideo
                  show={modalShow}
                  onHide={hideModal}
                  video={data.contentfulCareersAboutPage.heroButton.url}
                />
              </div>
              <div className="col-lg-6 hero-video-content">
                <p className="sub-heading">{data.contentfulCareersAboutPage.heroSubheading}</p>
                <h1 className="hero-heading">
                  Braving the <span className="text-secondary">uncharted</span>
                </h1>
                <RichTextRenderer json={data.contentfulCareersAboutPage.heroContent} noContainer />
              </div>
            </div>
          </div>
        </section>

        <AboutYellowContent
          header={data.contentfulCareersAboutPage.yellowContentHeader}
          content={data.contentfulCareersAboutPage.yellowContent}
          image={data.contentfulCareersAboutPage.missionImage}
        />

        <WidgetStats {...data.contentfulCareersAboutPage} />

        <div className="full-width-image">
          <GatsbyImage
            className="img-fluid js-parallax"
            image={{ ...data.contentfulCareersAboutPage.centralImage.fluid, aspectRatio: 21 / 9 }}
            loading="lazy"
            alt={data.contentfulCareersAboutPage.centralImage.title || ""}
          />
        </div>

        <AboutMission
          heading={data.contentfulCareersAboutPage.missionTitle}
          content={data.contentfulCareersAboutPage.missionContent}
        />

        <AboutMission
          heading={data.contentfulCareersAboutPage.sectionIntroHeader}
          content={data.contentfulCareersAboutPage.sectionIntroContent}
          className='lights'
        />

        <WidgetGroup region={data.contentfulCareersAboutPage.region} />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default ContentTemplate

export const query = graphql`
  query {
    contentfulCareersAboutPage(title: { eq: "Careers > About Page" }) {
      title
      seoTitle
      seoDescription

      heroSubheading
      heroHeading {
        ...RichTextField
      }
      heroContent {
        ...RichTextField
      }
      heroButton {
        ...ContentfulLink
      }
      heroImage {
        fluid: gatsbyImageData(placeholder: NONE,, width: 640, height: 400, quality: 100, formats: [AUTO, WEBP])
        title
      }

      yellowContentHeader
      yellowContent {
        ...RichTextField
      }

      statsHeader
      statsCards {
        ...ComponentCard
      }

      missionTitle
      missionContent {
        ...RichTextField
      }
      missionImage {
        fluid: gatsbyImageData(placeholder: NONE, width: 2048, height: 1500, quality: 100, formats: [AUTO, WEBP])
        title
      }

      centralImage {
        fluid: gatsbyImageData(placeholder: NONE, width: 2048, quality: 100, formats: [AUTO, WEBP])
        title
      }

      sectionIntroHeader
      sectionIntroContent {
        ...RichTextField
      }

      region {
        ...ComponentCTA
      }
    }
  }
`
