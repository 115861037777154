import React from "react"
import "./style.scss"
import RichTextRenderer from "../../../elements/RichTextRenderer"

interface IWidgetStats {
  statsHeader: string
  statsCards: Array<{
    heading: string
    subheading: string
    content: object
  }>
}

const WidgetStats: React.FC<IWidgetStats> = ({ statsHeader, statsCards }) => {
  return (
    <div className="stats">
      <div className="container position-relative">
        <span className="heading">Stats</span>
        <div className="row">
          <div className="col-md-11 col-lg-9 col-xxl-7 stats-intro">
            <h2 className="h1">{statsHeader}</h2>
          </div>
        </div>
        <div className="row ">
          {statsCards &&
            statsCards.map((stat, index) => (
              <div className={`col-6 col-md-4 stat-item fade-in-up`} key={`stats-${index}`}>
                <h3>
                  {stat.heading} <small>{stat.subheading}</small>
                </h3>
                <RichTextRenderer json={stat.content} noContainer />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default WidgetStats
