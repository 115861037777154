import React from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import "./style.scss"

interface IAboutMission {
  heading: string
  content: object
  className?: string
}

const AboutMission: React.FC<IAboutMission> = ({ heading, content, className = "" }) => {
  return (
    <section className={`about-mission ${className}`}>
      <div className="container">
        <div className="row position">
          <div className="col-lg-10">
            <h2>{heading}</h2>
          </div>
          <div className="col-lg-10">{content && <RichTextRenderer json={content} />}</div>
        </div>
      </div>
      <div className="imageContainer"></div>
    </section>
  )
}

export default AboutMission
